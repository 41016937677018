import React from "react";
import logo from "./logos/logo_transparent.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import HomePage from "./components/pages/HomePage";
import ServicesPage from "./components/pages/ServicesPage";
import ContactPage from "./components/pages/ContactPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
function App() {
  return (
    // <div className="App">
    //   <Navbar />
    //   <HomePage />
    // </div>
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </>
  );
}

export default App;
