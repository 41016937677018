import React from "react";
import "./ContactPage.css";
import { MdEmail, MdLocationOn } from "react-icons/md";
const ContactPage = () => {
  return (
    <div className="contact">
      <h1>Contact</h1>
      <div className="details">
        <ul id="contact-ul">
          <li>
            <MdEmail size={30} color="#edc06d" />{" "}
            <a href="mailto:info@aipcg.com">info@aipcg.com</a>
          </li>
          <li>
            <MdLocationOn size={30} color="#edc06d" />
            <a href="https://goo.gl/maps/3qWRZfDJmf4H4mc59">
              8105 Rasor Blvd Ste 298, Plano, TX 75024
            </a>{" "}
          </li>
        </ul>
        {/* <div className="contact-form">
          <h2>Contact Form</h2>
          <form action="/contact" method="post">
            <input type="text" name="name" placeholder="Your Name" />
            <input type="email" name="email" placeholder="Your Email" />
            <input type="text" name="subject" placeholder="Subject" />
            <textarea name="message" placeholder="Your Message"></textarea>
            <input type="submit" value="Send" />
          </form>
        </div> */}
      </div>
    </div>
  );
};

export default ContactPage;
