import React from "react";
import "./ServicesPage.css";

const ServicesPage = () => {
  return (
    <div className="services">
      <h1>Services</h1>
      <div className="details">
        Prosper Consulting Group is a certified public accounting company that
        provides a wide range of services to businesses of all sizes. Our
        services include:
        <ul id="services-ul">
          <li>
            Auditing: We provide auditing services to help businesses ensure
            that their financial statements are accurate and reliable.{" "}
          </li>
          <li>
            Tax planning: We help businesses develop tax-efficient strategies to
            save money.
          </li>
          <li>
            Consulting: We provide consulting services to help businesses
            improve their financial performance.
          </li>
          <li>
            Accounting: We provide accounting services to help businesses with
            their day-to-day financial recordkeeping.
          </li>
        </ul>
        We are committed to providing our clients with high-quality services
        that meet their individual needs. We are also committed to providing our
        clients with clear and concise communication so that they understand our
        services and how they can benefit their business.
      </div>
    </div>
  );
};

export default ServicesPage;
