import React from "react";
import "./HomePage.css";

const HomePage = () => {
  return (
    <div className="homepage">
      <div className="title">
        Making your business a success,
        <br />
        one number at a time
      </div>
      <button
        className="button"
        onClick={() => (window.location.href = "/contact")}
      >
        Get in touch
      </button>
    </div>
  );
};

export default HomePage;
